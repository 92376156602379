<template>
  <div>
    <breadcrumbs
      :page-title="pageTitle"
      :page-subtitle="pageSubtitle"
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <div
      v-if="Object.keys(account).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div
      v-else
    >
      <b-form @submit.prevent>
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>{{ $t('Account Details') }}</span>
            </template>
            <b-card>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <h4 class="mb-0">
                    {{ $t('Account Details') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('Enter Your Account Details') }}
                  </small>
                </div>
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="$router.go(-1)"
                />
              </div>

              <validation-observer ref="validationAccountRules">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('profile.usertitle')"
                      label-for="i-username"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Username"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-username"
                            v-model="account.name"
                            placeholder="johndoe"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="username"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Email"
                      label-for="i-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-email"
                            v-model="account.email"
                            autocomplete="username"
                            type="email"
                            placeholder="john.doe@email.com"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('profile.phone')"
                      label-for="i-phone"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SmartphoneIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-phone"
                          v-model="account.phone"
                          :placeholder="$t('profile.phoneexample')"
                        />
                      </b-input-group>
                    </b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('stats.country')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('stats.country')"
                        label-for="i-country"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <v-select
                          id="i-country"
                          v-model="country"
                          :placeholder="$t('sites.choosecountry')"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="target_countries"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('page.contacts.title')"
                    >
                      <div
                        v-for="(contact, index) in contacts"
                        :key="index"
                        class="contacts mb-1"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="socials[contact.type].text"
                          rules="required"
                        >
                          <b-input-group
                            :class="{
                              'is-invalid': errors.length > 0,
                            }"
                          >
                            <b-input-group-prepend>
                              <b-dropdown
                                variant="outline-secondary"
                                class=""
                              >
                                <template #button-content>
                                  <b-img
                                    width="16"
                                    height="16"
                                    class="align-middle"
                                    :src="socials[contact.type].src"
                                  />
                                </template>
                                <b-dropdown-item
                                  v-for="(key, idx) in Object.keys(socials)"
                                  :key="idx"
                                  @click="contactTypeUpdate(key, index)"
                                >
                                  <b-img
                                    width="19"
                                    height="19"
                                    :src="socials[key].src"
                                  /> {{ socials[key].text }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="contacts[index].value"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="socials[contact.type].text"
                            />
                            <b-input-group-append>
                              <b-button
                                v-if="index === 0"
                                variant="outline-primary"
                                @click="contactAdd()"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="cursor-pointer"
                                />
                              </b-button>
                              <b-button
                                v-if="index > 0"
                                variant="outline-danger"
                                @click="contactRemove(index)"
                              >
                                <feather-icon
                                  icon="TrashIcon"
                                  class="cursor-pointer"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-form-group>
                    <b-form-group
                      :label="$t('registration.accountcurrency')"
                      label-for="i-currency"
                    >
                      <b-form-input
                        id="i-currency"
                        :placeholder="$t('registration.selectcurrency')"
                        :value="account.currency"
                        disabled
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('profile.notes')"
                      label-for="i-notes"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="FileTextIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-notes"
                          v-model="account.notes"
                          :placeholder="$t('profile.notes')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="accountUpdate"
                    >
                      <span class="align-middle">{{ $t('Save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card>
          </b-tab>
          <b-tab v-if="authUserData.role.includes('admin')">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>{{ $t('Roles') }}</span>
            </template>
            <b-card>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <h4 class="mb-0">
                    {{ $t('Roles') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('Change user roles') }}
                  </small>
                </div>
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="$router.go(-1)"
                />
              </div>
              <validation-observer ref="validationRolesRules">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="User roles"
                      label-for="i-roles"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="User role"
                        rules="required"
                      >
                        <v-select
                          id="i-roles"
                          v-model="selectedRoles"
                          multiple
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userRoles"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="rolesUpdate"
                    >
                      <span class="align-middle">{{ $t('Save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" />
              <span>{{ $t('Password') }}</span>
            </template>
            <b-card>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <h4 class="mb-0">
                    {{ $t('Password') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('Change password') }}
                  </small>
                </div>
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="$router.go(-1)"
                />
              </div>
              <validation-observer ref="validationPassRules">
                <b-row>
                  <b-col
                    v-if="!authUserData.role.includes('admin')"
                    md="12"
                  >
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Old Password"
                          label-for="o-password"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Old Password"
                            rules="required"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length == 0 || 'is-invalid'"
                            >
                              <b-form-input
                                id="o-password"
                                v-model="passwordValueOld"
                                :type="passwordFieldTypeOld"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Old password"
                                autocomplete="old-password"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  :icon="passwordToggleIconOld"
                                  class="cursor-pointer"
                                  @click="togglePasswordOld"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="New password"
                      label-for="i-password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="New password"
                        vid="Password"
                        rules="required|min:8|password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-form-input
                            id="i-password"
                            v-model="newPasswordValue"
                            :type="passwordFieldTypeNew"
                            :state="errors.length > 0 ? false:null"
                            placeholder="New password"
                            autocomplete="new-password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIconNew"
                              class="cursor-pointer"
                              @click="togglePasswordNew"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Confirm Password"
                      label-for="i-c-password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Re-type Password"
                        rules="required|confirmed:Password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-form-input
                            id="i-c-password"
                            v-model="RetypePassword"
                            :state="errors.length > 0 ? false:null"
                            :type="passwordFieldTypeRetype"
                            placeholder="Re-type Password"
                            autocomplete="new-password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIconRetype"
                              class="cursor-pointer"
                              @click="togglePasswordRetype"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('Back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="passwordUpdate"
                    >
                      <span class="align-middle">{{ $t('Update password') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>{{ $t('Referral') }}</span>
            </template>
            <b-card>
              <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                  <h4 class="mb-0">
                    {{ $t('Referral') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('Referral program') }}
                  </small>
                </div>
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="$router.go(-1)"
                />
              </div>
              <b-row>
                <b-col
                  v-if="authUserData.role.includes('admin')"
                  md="6"
                >
                  <b-form-group
                    label="Affiliate earn percent"
                    label-for="i-affiliate-percent"
                  >
                    <b-form-input
                      id="i-affiliate-percent"
                      v-model="account.affiliate_earn_percent"
                      placeholder="0.0"
                      type="number"
                      step="0.1"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="authUserData.role.includes('admin')"
                  md="6"
                >
                  <b-form-group
                    label="Affiliate user ID"
                    label-for="i-affiliate-uid"
                  >
                    <b-form-input
                      id="i-affiliate-uid"
                      v-model="account.affiliate"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Referral link"
                    label-for="i-referral-link"
                  >
                    <b-input-group>
                      <b-form-input
                        id="i-referral-link"
                        v-model="refLink"
                      />
                      <b-input-group-append>
                        <b-button
                          v-clipboard:copy="refLink"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-primary"
                        >
                          {{ $t('Copy') }}!
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>Share this and get bonus revenue from publisher that signs by this URL</span>
                    </div>
                  </b-alert>
                </b-col>
                <b-col
                  md="12"
                  class="d-flex align-items-center justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-1"
                    @click="$router.go(-1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Back') }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="accountUpdate"
                  >
                    <span class="align-middle">{{ $t('Save') }}</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="ml-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormTextarea,
  BSpinner,
  BTabs,
  BTab,
  BCard,
  BButton,
  BAlert,
  BImg,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  min,
  confirmed,
  email,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BSpinner,
    vSelect,
    BTabs,
    BTab,
    BCard,
    BButton,
    BAlert,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageTitle: this.$t('Edit profile'),
      pageSubtitle: '',
      breadcrumbItems: [],
      authUserData: getUserData(),
      min,
      confirmed,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
      email,
      account: {},
      country: {},
      refLink: `${window.location.protocol}//${window.location.host}/register?ref=${this.$route.params.userId}`,
      selectedRoles: [],
      userRoles: [
        { value: 'Admin', text: 'Admin' },
        { value: 'AdminReadOnly', text: 'AdminReadOnly' },
        { value: 'Default', text: 'Default' },
        { value: 'DefaultReadOnly', text: 'DefaultReadOnly' },
        { value: 'Agency', text: 'Agency' },
        { value: 'AgencyMember', text: 'AgencyMember' },
        { value: 'Dummy', text: 'Dummy' },
        { value: 'SocialPublisher', text: 'SocialPublisher' },
        { value: 'GeoStats', text: 'GeoStats' },
        { value: 'Subscribe', text: 'Subscribe' },
        { value: 'Affiliate', text: 'Affiliate' },
      ],
      ...options,
      socials: {
        telegram: {
          src: require('@/assets/images/icons/telegram-brands.svg'),
          text: 'Telegram',
        },
        whatsupp: {
          src: require('@/assets/images/icons/whatsapp-brands.svg'),
          text: 'Whatsapp',
        },
        viber: {
          src: require('@/assets/images/icons/viber-brands.svg'),
          text: 'Viber',
        },
        skype: {
          src: require('@/assets/images/icons/skype-brands.svg'),
          text: 'Skype',
        },
        other: {
          src: require('@/assets/images/icons/speaker.svg'),
          text: 'Other',
        },
      },
      contacts: [
        { type: 'telegram', value: '' },
      ],
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getAccountData()
  },
  methods: {
    async getAccountData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      if (this.account.roles.length) {
        this.selectedRoles = this.account.roles.map(r => ({ value: r, text: r }))
      }
      if (this.account.country !== '' && this.account.country !== null) {
        const countryText = this.account.country.charAt(0).toUpperCase() + this.account.country.slice(1)
        this.country = { value: this.account.country, text: countryText }
      } else {
        this.country = null
      }
      if (this.account.contacts !== null && this.account.contacts.length !== 0) {
        this.contacts = this.account.contacts
      } else if (this.account.skype) {
        this.contacts.unshift({ type: 'skype', value: this.account.skype })
      }
      this.setBreadcrumbs()
    },
    accountUpdate() {
      this.$refs.validationAccountRules.validate().then(success => {
        if (success) {
          this.account.country = this.country.value
          this.account.contacts = this.contacts
          useJwt.setUser(this.$route.params.userId, { account: this.account })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Account saved',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    const field = err.source.pointer.split('/')
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: `Error [${field[field.length - 1]}]`,
                        msg: err.detail,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  })
                }
              }
              if (error.response && error.response.status === 500) {
                this.$store.dispatch(
                  'error-store/showNotify',
                  {
                    title: error.response.statusText,
                    msg: error.message,
                    variant: 'danger',
                  },
                  { root: true },
                )
              }
            })
        }
      })
    },
    passwordUpdate() {
      this.$refs.validationPassRules.validate().then(success => {
        if (success) {
          let passwordInfo = {}
          if (this.authUserData.role.includes('admin')) {
            passwordInfo = {
              newPassword: this.newPasswordValue,
            }
          } else {
            passwordInfo = {
              oldPassword: this.passwordValueOld,
              newPassword: this.newPasswordValue,
              newPassword1: this.RetypePassword,
            }
          }
          useJwt.setUserPassword(this.$route.params.userId, passwordInfo)
            .then(response => {
              if (response.data.result === 'Ok') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'New password saved',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    rolesUpdate() {
      this.$refs.validationRolesRules.validate().then(success => {
        if (success) {
          const newRoles = this.selectedRoles.map(i => (i.value))
          useJwt.setUserRoles(this.$route.params.userId, { roles: newRoles })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Roles saved',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Referral link copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    setBreadcrumbs() {
      if (this.account.is_admin) {
        this.breadcrumbItems = [
          {
            text: this.$t('page.menu.users'),
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: this.pageTitle,
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: this.$t('page.menu.users'),
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: this.pageTitle,
            active: true,
          },
        ]
      }
    },
    contactAdd() {
      this.contacts.push({ type: 'telegram', value: '' })
    },
    contactTypeUpdate(type, index) {
      this.contacts[index].type = type
    },
    contactRemove(id) {
      this.contacts.splice(id, 1)
    },
  },
}
/* eslint-disable global-require */
</script>
